* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Reem Kufi", sans-serif !important;
}

img {
  max-width: 100%;
  height: auto;
}

.App {
  min-height: 100vh;
  width: 85vw;
  max-width: 1900px;
  margin: 0rem auto;
}

html {
  overflow-x: hidden !important;
  scroll-behavior: smooth;
}

body {
  max-width: 100%;
  background-color: #f6f6f6;
}

/* Classe para desabilitar elementos */
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
}

.navbar-menu-container {
  display: none;
}

.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
  font-weight: 600;
  border-bottom: transparent 2px solid;
  transition: border-color 0.3s ease;
}

.navbar-links-container a:hover {
  border-color: #fff;
}

.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}


.primary-button:hover {
  background-color: rgb(234, 234, 234);
}

.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}


.button-style {
  padding: 5px 25px;
  background-color: #fe9e0d;
  border-radius: 5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
  width: 100%;
  text-align: center;
}

.button-style:focus {
  outline: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.scrollToTopButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #333;
  color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 999;
}

.scrollToTopButton.show {
  opacity: 1;
  visibility: visible;
}

.scrollToTopButton:hover {
  background-color: #555;
  cursor: pointer;
}

.home-container .logo img {
  padding-top: 20px;
}

.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 3rem;
}

.home-bannerImage-container {
  position: absolute;
  overflow-x: hidden !important;
  overflow-y: hidden !important;

  top: -120px;
  right: -170px;
  z-index: -2;
  max-width: 700px;
}

.home-image-section {
  max-width: 600px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.primary-heading {
  font-size: clamp(2rem, 5vw, 4rem);
  color: #4c4c4c;
  line-height: 4rem;
  margin-bottom: 2rem;
}

.primary-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 770px;
  color: #6a6a6a;
  margin: .5rem 0rem;
}

.secondary-button {
  margin-top: 3rem;
  padding: 1rem 2.5rem;
  background-color: #fe9e0d;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}

.secondary-button:hover {
  background-color: #e48f0f;
}

.about-section-container {
  margin-top: 6rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about-background-image-container {
  position: absolute;
  left: -195px;
  z-index: -2;
}

.about-section-image-container {
  flex: 0.9;
  margin-right: 1rem;
}

.about-section-text-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.primary-subheading {
  font-weight: 700;
  color: #fe9e0d;
  font-size: 1.15rem;
}

.about-buttons-container {
  margin-top: 2rem;
  display: flex;
}

/* Estilos para a seção de download */

/* Contêiner da seção de download */
.download-section-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Estilo da imagem na seção de download */
.download-section-container img {
  width: 1000px;
  filter: grayscale(100);
}

/* Contêiner da imagem na seção de download */
.download-section-image-container {
  margin-right: 1rem;
}

/* Contêiner do texto na seção de download */
.download-section-text-container {
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

/* Estilo para o parágrafo na seção de download */
.download-versao p {
  margin-top: 10px;
  text-align: center;
}

/* Estilos para a seção de rodapé */

/* Contêiner do rodapé */
.footer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0rem;
  margin-top: 10rem;
}

/* Logotipo no rodapé */
.footer-logo {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
  align-items: center;
  align-content: center;
}

/* Ícones no rodapé */
.footer-icons {
  display: flex;
  gap: 10px;
}

/* Estilo dos links de ícones */
.footer-icons a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fe9e0d;
  color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

/* Efeito de destaque ao passar o mouse sobre os ícones */
.footer-icons a:hover {
  transform: translateY(-2px);
  background-color: #e48d0b;
}

/* Estilo dos ícones no rodapé */
.footer-icons svg {
  font-size: 1.5rem;
  color: #fff;
}

/* Estilo para uma imagem de logotipo */
.logo {
  filter: brightness(0);
  width: 155px;
}

@media (max-width: 1000px) {
  /* Estilos para dispositivos com largura máxima de 1000px */

  .navbar-links-container a {
    margin-right: 1rem;
    /* Reduz a margem direita dos links no navbar */
    font-size: 1rem;
    /* Reduz o tamanho da fonte nos links no navbar */
  }

  .primary-button {
    font-size: 1rem;
    /* Reduz o tamanho da fonte no botão primário */
  }

  .home-bannerImage-container {
    max-width: 600px;
    /* Define a largura máxima do container da imagem na página inicial */
  }
}


@media (max-width: 800px) {

  .drawer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 20px);

  }

  .drawer img {
    margin-bottom: 100px;
  }

  .drawer-links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
  }

  .drawer-links-container a {
    color: #fff;
    text-decoration: none;
  }

  body {
    overflow-x: hidden;
  }

  .nav-logo-container {
    max-width: 140px;
  }

  .navbar-links-container {
    display: none;
  }

  .navbar-menu-container {
    display: flex;
  }

  .home-bannerImage-container {
    max-width: 450px;

  }

  .home-banner-container,
  .about-section-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .about-section-container {
    flex-direction: column;
  }

  .home-image-section,
  .about-section-image-container {
    width: 100%;
    max-width: 400px;
  }

  .primary-heading {
    text-align: center;
    /* max-width: 90%; */
    line-height: 2.4rem;
  }

  .primary-text {
    text-align: center;
    max-width: 80%;
  }

  .home-text-section,
  .about-section-text-container {
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }

  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }

  .about-section-container {
    margin-top: 5rem !important;
  }

  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
  }

  .primary-subheading {
    text-align: center;
  }

  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
  }

  .contact-form-container input {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }

}

@media (max-width: 600px) {
  .download-section-container {
    flex-direction: column;
  }

  .secondary-button {
    margin: 0;
  }

  .footer-wrapper {
    margin-top: 4rem;
  }
}